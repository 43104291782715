import React, { useEffect } from 'react';
import Lock from "../../assets/images/svg/icons/unlocked-lock.svg"
import Money from "../../assets/images/svg/icons/Money-Icon.svg"
import Conversation from "../../assets/images/svg/icons/Conversation-Bubbles.svg"




function SolveSolutionBullets() {

    {/* header */ }
    return (

        <div className='inverted'>
            <canvas className="orb-canvas" data-aos="fade-in" />
            <>
               <section className='digital-solution'>
                    <div className='container'>
                        <div className='solution-top'>
                            <h2><span className='text-green'>Simplified Solutions</span> For Complex Business Problems.</h2>
                            <p>Our seasoned team innovates to resolve your intricate challenges. Through streamlined processes, simplified pricing, and rapid responses, we prioritize your success. Collaborating seamlessly with all stakeholders, we ensure comprehensive results.</p>
                        </div>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='solution-box'>
                                    <span className='icon'><img src={Conversation} alt='Chat Bubbles'/></span>
                                    <h4>Consultancy Based</h4>
                                    <p>We begin by defining the desired outcome, then tailor a suitable blend of technology to effectively address your needs.</p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='solution-box'>
                                    <span className='icon'><img src={Money} alt='Money'/></span>
                                    <h4>Flat-Rate Pricing</h4>
                                    <p>Leveraging our proprietary pricing algorithm ensures your project maintains a flat-rate structure, free from unexpected surprises.</p>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='solution-box'>
                                    <span className='icon'><img src={Lock} alt='Unlocked Lock'/></span>
                                    <h4>Vendor Agnostic</h4>
                                    <p>Our focus is on delivering optimal solutions, prioritizing your needs over promoting  particular vendors or product lines.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> 
             
            </> 

            
            

          
        </div>
    );
}



export default SolveSolutionBullets;