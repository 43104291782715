import React, { useState, useEffect } from 'react';
import "../../assets/css/mainHeroCards.css"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import heroRight from "../../assets/images/hero_col-right.jpg"
import arrowRight from "../../assets/images/button_arrow_light.svg"
import BtnArrowdefault from "../../assets/images/button_arrow-top.svg"
import BtnArrowbottom from "../../assets/images/button_arrowbottom_white.svg"
import FrameIocn from "../../assets/images/farme-icon.png"
import sliderImage1 from "../../assets/images/SolveImages/Business-team-meeting-with-people.jpg"
// import arrowSliderLeft from "../assets/images/arrow-left.svg"
// import arrowSliderRight from "../assets/images/arrow-right.svg"
import { Pagination, Navigation } from "swiper";




function SolveSolutionHero(props) {

    {/* header */ }
    const [swiper, setSwiper] = useState(null);
    const handleButtonClick = (index) => {
        if (swiper) {
            swiper.slideTo(index);
        }
    }
    return (

        <div className='inverted'>
            <canvas className="orb-canvas" data-aos="fade-in" />
            <>
                {/* Hero*/}
                <section className="container pt-20 main-hero solution-hero" >
                    <div className="row pt-2">
                        {/* Text + CTA button*/}
                        <div className=" col-lg-7 d-flex justify-content-center flex-column mb-4 mb-lg-0 pb-sm-3 pb-lg-0">
                            <span className='hero-label badge bg-muted-purple text-white rounded-pill mb-3'>Solve a Problem</span>
                            <h1 class="display-1 hero-title">We <span className="text-green">Solve</span> Business Bottlenecks.</h1>
                            <p>Delivering precise solutions to address your unique needs, navigating even the most intricate challenges for organizations of all sizes.</p>
                            {/* <p class="text-white" style={{ marginBottom: '2rem' }}>Cutting-Edge Technology Made Easy. </p> */}
                            {/* <a href="https://patch.com/new-jersey/oceancity/f-s-digital-named-best-new-family-owned-business-nodx" target="_blank"><span
                                class="badge bg-opaque-green text-green rounded-pill">News: "F&S, Top Family-Owned Business NJ"</span></a> */}
                            <div className='help-bottom'>

                                <div className="d-flex align-items-center pt-0 mt-0">
                                    <a className="btn border-purple btn-md text-white rounded-0 me-2 me-sm-3 talk-btn" href="/contact/">
                                        Schedule Consultation
                                        <div className="arrow_movement">
                                            <img src={BtnArrowdefault} alt='' />
                                            <img src={BtnArrowbottom} alt='' />
                                        </div>
                                    </a>

                                </div>
                                {/* <span className='over-title'><img src={FrameIocn} alt=''/>Over 300 companies impacted</span> */}
                            </div>
                            {/* <ul className="nav mt-auto mx-n2 pt-sm-3 pt-lg-0">
                                <li className="nav-item me-3">
                                    <a className="nav-link p-2" href="#">
                                        instagram
                                    </a>
                                </li>
                                <li className="nav-item me-3">
                                    <a className="nav-link p-2" href="#">
                                        dribbble
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link p-2" href="#">
                                        behance
                                    </a>
                                </li>
                            </ul> */}
                        </div>
                        {/* Services (grid of cards)*/}
                        <div className="col-lg-5">
                            <div className="swiper-container">
                                <Swiper
                                    modules={[Pagination, Navigation]}
                                    pagination={{ clickable: true }}
                                    spaceBetween={0}
                                    initialSlide={1}
                                    centeredSlides={true}
                                    slidesPerView={1}
                                    onSwiper={(swiper) => {
                                        setSwiper(swiper);
                                    }}
                                    onActiveIndexChange={(swiper) => {
                                        // console.log("active index is", swiper.activeIndex);
                                    }}
                                >
                                    <div className="container">
                                        <SwiperSlide className="capability-box">
                                            <div className="row align-items-center">
                                                <div className="col-md-12">
                                                    <img src={sliderImage1} alt="sliderImage" />
                                                </div>

                                            </div>
                                        </SwiperSlide>
                                        {/* <SwiperSlide className="capability-box">
                                <div className="row align-items-center">
                                <div className="col-md-12">
                                    <img src={sliderImage1} alt="sliderImage"/>
                                    </div>
                                </div>
                                </SwiperSlide>
                                <SwiperSlide className="capability-box">
                                <div className="row align-items-center">
                                <div className="col-md-12">
                                    <img src={sliderImage1} alt="sliderImage"/>
                                    </div>
                                </div>
                                </SwiperSlide>
                                <SwiperSlide className="capability-box">
                                <div className="row align-items-center">
                                <div className="col-md-12">
                                    <img src={sliderImage1} alt="sliderImage"/>
                                    </div>
                                </div>
                                </SwiperSlide>
                                <SwiperSlide className="capability-box">
                                <div className="row align-items-center">
                                    <div className="col-md-12">
                                    <img src={sliderImage1} alt="sliderImage"/>
                                    </div>
                                </div>
                                </SwiperSlide> */}
                                    </div>

                                </Swiper>
                                {/* <div className='swipper_owl'>
                                <div className="swiper-button-next"><img src={arrowSliderLeft} alt=''/></div>
                                <div className="swiper-button-prev"><img src={arrowSliderRight} alt=''/></div>
                            </div> */}
                            </div>
                        </div>

                    </div>
                </section>
            </>

            <span
                style={{ zIndex: '-999', }}
                className="blob blurred"
                data-top-top="transform: translateY(0%);"
                data-top-bottom="transform: translateY(10%);"
            ></span>



            {/* <div className='ticker-spacing'>
                <TickerEx theTextToShow="         Websites         Web Apps         Mobile Apps         Secure Hosting         Completely Custom Ideas🤙🏼" />
            </div> */}
        </div>
    );
}



export default SolveSolutionHero;